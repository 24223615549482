<template>
  <div class="box">

    <div style="height: 100vh; overflow: scroll;padding-bottom: 3rem;">
      <div v-for="(item, index) in cakaData?.productSubOrderList">
        <!-- 循环商品信息 -->
        <div :class="index == 0 ? 'statusW' : 'statusB'">
          {{ getStatus(item.status) }}
        </div>
        <div class="bgc" style="padding-bottom: 1rem;">
          <div class="flex">
            <div>
              <img :src="item.url" alt="" class="img" />
            </div>
            <div class="p-lr-10" style="font-size: 1rem;flex: 1 1;min-height: 6rem;">
              <span class="textTitle"> {{ item.productName }}</span>
              <span class="textcontext"> {{ item.skuName }}</span>
            </div>
            <div style="margin-right: 0.2rem;display: flex;flex-direction: column; text-align: end;min-height: 6rem;">
              <span style="font-size: 1.1rem;font-weight: 600;">{{ item.showPrice }}点</span>
              <span style="font-size: 0.9rem;margin-top: 0.3rem;">x {{ item.emptionNum }}</span>
            </div>
          </div>
          <div v-if="item.blessings" class="sendjcsb" style="border-top: 1px solid #ededed; margin-top: 0.5rem;">
            <span style=" margin: 0.5rem 0; ">祝福语：{{ item.blessings }}</span>
          </div>
          <div v-if="item.buyerMsg" class="sendjcsb" style="border-top: 1px solid #ededed; margin-top: 0.5rem;">
            <span style=" margin: 0.5rem 0; ">备注：{{ item.buyerMsg }}</span>
          </div>
          <div class="sendjcsb" style="border-top: 1px solid #ededed; margin-top: 0.5rem;">
            <span style=" margin: 0.5rem 0; color: #888;">配送方式</span>
            <span style=" margin: 0.5rem; ">{{ getSendType(item) }}</span>

          </div>
          <div v-if="!ifShoow(item)" class="sendjcsb">
            <span style=" margin: 0.5rem 0; color: #888;min-width: 5rem;">配送地址</span>
            <span style=" margin: 0.5rem; text-align: end;">{{
        item.consigneeAddress + '(' + item.consigneeName + '-' + getPhone(item.consigneePhone) + ')'
      }}</span>
          </div>
          <div v-if="(item.shipType && item.shipDate) || (item.ship_type && item.ship_date)" class="sendjcsb">
            <span style=" margin: 0.5rem 0; color: #888;">{{ getSendType1(item) }}</span>
            <span style=" margin: 0.5rem; ">{{ getSendType2(item) }}</span>
          </div>
          <div v-if="ifShoow(item)" class="sendjcsb">
            <span style=" margin: 0.5rem 0; color: #888;min-width: 5rem;">门店名称</span>
            <span style=" margin: 0.5rem; ">{{ item.shopName ? item.shopName :
        item.take_shop_name
              }}</span>
          </div>
          <div v-if="ifShoow(item)" class="sendjcsb">
            <span style=" margin: 0.5rem 0; color: #888;min-width: 5rem;">门店地址</span>
            <span style=" margin: 0.5rem; ">{{ item.shopDetail ? item.shopDetail : item.addr
              }}</span>
          </div>
          <div v-if="item?.logisticsInfo && item?.logisticsInfo.length > 0" class="itembottom">
            <div></div>
            <div class="flex">
              <div style="border: 1px solid #aea9a9; ; border-radius: 1rem; padding: 0.2rem 0.8rem; margin-top: 0.5rem;"
                @click.stop="goLogistics(item)">
                查看物流
              </div>
              <div v-if="item.status == '10'"
                style="border: 1px solid #D23B2E; ; border-radius: 1rem; padding: 0.2rem 0.8rem; color: #D23B2E; margin-top: 0.5rem;margin-left: 0.5rem;"
                @click.stop="sureget(item.id)">
                确认收货
              </div>
            </div>
          </div>
          <div v-if="item.cardPwd" style="border-top:1px dashed #888 ; margin-top: 0.5rem;padding-top: 0.8rem;">
            <div @click="showcard(item.id)"
              style="width: 100%; display: flex;flex-direction: row;justify-content: center;height: 1.5rem; line-height: 1.5rem;align-items: center;">
              卡号密码
              <van-image style="margin-left: 0.5rem;" width="0.7rem" height="0.5rem" v-if="item.show" :src="jtup" />
              <van-image style="margin-left: 0.5rem;" width="0.7rem" height="0.5rem" v-else :src="jtdown" />
            </div>
            <div v-if="item.show" v-for="(item1, index) in item.cardPwd" class="cardnp">
              <div v-if="item1.cardNum" class="flex jcsb p-b-10">
                <span> 卡号{{ index + 1 }}：{{ item1.cardNum }}</span>
                <span @click="copyText(item1.cardNum)"> 复制</span>
              </div>
              <div v-if="item1.cardPwd" class="flex jcsb p-b-10">
                <span> 密码：{{ item1.cardPwd }}</span>
                <span @click="copyText(item1.cardPwd)"> 复制</span>
              </div>
              <div v-if="item1.exchangeUrl" class="flex jcsb p-b-10">
                <span class="ellipsis"> 兑换链接：{{ item1.exchangeUrl }}</span>
                <span style="min-width: 2.5rem; text-align: end;" @click="copyText(item1.exchangeUrl)"> 复制</span>
              </div>
              <div v-if="item1?.expireDate" class="flex jcsb p-b-10">
                <span> 有效期：{{ $moment(item1?.expireDate).format("YYYY-MM-DD") }}</span>
              </div>
              <div v-if="item1.exchangeWebsite">
                <span> 兑换说明： {{ item1.exchangeWebsite }}</span>
                <div class="flex jcsb p-b-10">
                  <spa> </spa>
                  <span @click="copyText(item1.exchangeWebsite)"> 复制</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bgc" v-if="cakaData?.takeMealCode" style="padding-bottom: 0.8rem;">
        <div @click="showInter()"
          style="width: 100%; display: flex;flex-direction: row;justify-content: center;height: 1.5rem; line-height: 1.5rem;align-items: center;">
          取餐信息
          <van-image style="margin-left: 0.5rem;" width="0.7rem" height="0.5rem" v-if="showInters" :src="jtup" />
          <van-image style="margin-left: 0.5rem;" width="0.7rem" height="0.5rem" v-else :src="jtdown" />
        </div>
        <div v-if="showInters" class="cardnp">
          <div v-if="cakaData.consigneePhone" class="flex jcsb p-b-10">
            <span> 取餐手机号：{{ cakaData.consigneePhone }}</span>
          </div>
          <div v-if="cakaData.takeMealCode" class="flex jcsb p-b-10">
            <span style="display: flex; justify-content: flex-start;">
              取餐码：
              <ul>
                <li v-for="item in takeMealCodeArr">
                {{ item }}
              </li>
              </ul>
              
            </span>
          </div>
          <div v-if="cakaData.shipTimeText" class="flex jcsb p-b-10">
            <span class="ellipsis"> 出餐时间：{{ cakaData.shipTimeText }}</span>
          </div>
        </div>
      </div>

      <div class="bgc" style="padding-bottom: 1rem;margin-bottom: 2rem; ">
        <div style="font-size: 1.2rem;font-weight: 600; margin: 0.5rem;">订单信息</div>

        <div style="display: flex;  justify-content: space-between;font-size: 1rem;">
          <span style=" margin: 0.5rem; color: #888;">订单编号</span>
          <span style=" margin: 0.5rem; ">{{ orderNo }}</span>
        </div>
        <div style="display: flex; flex-direction: row; justify-content: space-between;font-size: 1rem; ">
          <span style="margin: 0.5rem; color: #888;">下单时间</span>
          <span style=" margin: 0.5rem; ">{{ cakaData?.createAt }}</span>
        </div>
        <div v-if="cakaData?.consumptionType" @click="showPayDetail"
          style="display: flex; flex-direction: row; justify-content: space-between; font-size: 1rem;">
          <span style=" margin: 0.5rem; color: #888;">支付方式</span>
          <span style=" margin: 0.5rem; ">{{ getPayType() }} <van-icon name="arrow" /></span>

        </div>
        <div v-if="cakaData?.postMoney"
          style="display: flex; flex-direction: row; justify-content: space-between; font-size: 1rem;">
          <span style=" margin: 0.5rem; color: #888;">快递费</span>
          <span style=" margin: 0.5rem; ">{{ cakaData?.postMoney }}点</span>
        </div>


        <div v-if="Paystatus !== '4' && Paystatus !== '5'"
          style="display: flex;  justify-content: space-between;font-size: 1rem;">
          <span v-if="Paystatus == '0'" style=" margin: 0.5rem; color: #888;">需付款</span>
          <span v-else style=" margin: 0.5rem; color: #888;">实付款</span>
          <div>
            <div class="price">
              <div v-if="cakaData?.originalPrice" style="text-align: end; ">
                <span style="font-size: 1.1rem;font-weight: 600;">{{ cakaData?.originalPrice }}</span>
                <span style="font-size: 0.9rem;">点</span>
              </div>
            </div>
            <div  v-if="cakaData.userRefundAmount" class="priceB">
              <div style="text-align: end; ">
              <span style="font-size: 0.8rem;">（ 已退{{ cakaData.userRefundAmount }}</span>
              <span style="font-size: 0.8rem;margin-top: 0.2rem;">点 ）</span>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="Paystatus === '0'" class="bottom">
      <div class="price">
        <span style="font-size: 0.9rem;margin-top: 0.1rem;font-weight: 500;">需支付：</span>
        <div v-if="cakaData?.originalPrice" style="text-align: end; ">
          <span style="font-size: 1.1rem;font-weight: 600;">{{ cakaData?.originalPrice }}</span>
          <span style="font-size: 0.9rem;">点</span>
        </div>
      </div>

      <div class="flex">
        <div style="border: 1px solid #aea9a9; ; border-radius: 1rem; padding: 0.2rem 0.8rem;  "
          @click.stop="showDialog">
          取消订单
        </div>
        <div
          style="border: 1px solid #D23B2E; ; border-radius: 1rem; padding: 0.2rem 0.8rem; color: #D23B2E;margin: 0.5rem 1rem 0.5rem 0.5rem;"
          @click.stop="changePay">
          去支付
        </div>
      </div>
    </div>


    <Translate ref="translate" v-if="showWL" :logistic-code="LogisticCode" :shipper-name="ShipperName"
      :data-list="logisticsInfo" :logistics-list="logisticsList" @closed="closedadd" @SelectTab="getLogistics"
      :active="(logisticsList.length - 1)">
    </Translate>
    <PayList v-if="showPL" :pay-list="payList" @closed="closedPay">
    </PayList>
    <div v-if="showTag" class="goHomeTag" @click="goHome"> 回到首页</div>
  </div>
</template>

<script>

import Translate from "../../components/order/Translate.vue";
import PayList from "../../components/order/PayList.vue";
export default {
  name: "",

  data() {
    return {
      jtup: require('@/assets/images/jtup2.png'),
      jtdown: require('@/assets/images/jtdown.png'),
      phone: '',
      cakaData: null,
      Paystatus: '',
      userId: '',
      pic: '',
      sfOrderNo: false,
      sfOrderNo: '',
      weiPrice: null,
      orderNo: '',
      selectIndex: 0,
      showWL: false,
      logisticsList: [],
      showAll: false,
      orderType: '',
      LogisticCode: '',
      ShipperName: '',
      showPL: false,
      payList: [],
      logisticsInfo: [],
      showTag: false,
      showInters: false,
    };
  },
  components: { Translate, PayList },
  computed: {
    takeMealCodeArr: function() {
      if(!this.cakaData.takeMealCode) {
        return []
      }; 
      const strArr = this.cakaData.takeMealCode.split(',');
      return strArr;
    }
  },
  methods: {
    copyText(row) {
      this.$copyText(row).then(
        (e) => {
          this.$Toast('复制成功!')
          console.log(e);
        },
        (err) => {
          this.$Toast('复制失败,请重试!')
          console.log(err);
        }
      );
    },
    showPayDetail() {
      if (this.payList && this.payList.length > 0) {
        this.showPL = true
      }
    },
    showInter() {
      if (this.showInters) {
        this.showInters = false
      } else
        this.showInters = true
    },
    showcard(id) {
      this.cakaData.productSubOrderList.forEach((item, index) => {
        if (item.id == id) {
          if (item.show) {
            item.show = false
          } else {
            item.show = true
          }
          this.$set(this.cakaData.productSubOrderList, index, item);
        }
      });
    },
    sureget(val) {
      this.$api
        .cakeConfirmReceipt(this.orderNo, val)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
          } else {
            this.$Toast(res.msg);
            this.getOrderzyDetail()
          }
        })
        .catch((err) => {
          this.$Toast(err.msg);
        });
    },
    closedadd(val) {
      this.showWL = val
    },
    closedPay(val) {
      this.showPL = val
    },
    getPayType() {
      if (this.cakaData?.consumptionType == '0') {
        return '卡余额支付'
      } else if (this.cakaData?.consumptionType == '1') {
        return '在线支付'
      } else if (this.cakaData?.consumptionType == '2') {
        return '卡余额支付+微信支付'
      }
    },
    getSendType(val) {
      if (val?.shipType) {
        if (val?.shipType == 'delivery') {
          return '商家配送'
        } else if (val?.shipType == 'shop') {
          return '门店自提'
        } else {
          return '快递配送'
        }
      } else {
        if (val?.ship_type == 'delivery') {
          return '商家配送'
        } else if (val?.ship_type == 'shop') {
          return '门店自提'
        } else {
          return '快递配送'
        }
      }

    },
    ifShoow(val) {
      if (val.shipType && val.shipType == 'shop') {
        return true
      } else if (val.ship_type && val.ship_type == 'shop') {
        return true
      } else {
        return false
      }
    },

    getSendType1(val) {
      if (val?.shipType && val.shipDate) {
        if (val?.shipType == 'delivery') {
          return '配送时间'
        } else if (val.shipType == 'shop') {
          return '自提时间'
        } else {
          return '配送时间'
        }
      } else if (val?.ship_type && val.ship_date) {
        if (val?.ship_type == 'delivery') {
          return '配送时间'
        } else if (val?.ship_type == 'shop') {
          return '自提时间'
        } else {
          return '配送时间'
        }
      }
    },
    getSendType2(val) {
      if (val?.shipType && val?.shipDate) {
        return val?.shipDate + ' ' + val?.shipTimeText
      } else if (val?.ship_type && val?.ship_date) {
        return this.$moment(val?.ship_date).format("MM月DD日") + ' ' + val?.ship_time_text
      }
    },
    goLogistics(val) {
      if (val.logisticsInfo && val.logisticsInfo.length > 0) {
        this.logisticsInfo = val.logisticsInfo
        this.getLogistics(this.logisticsInfo[0])
      }
    },
    getLogistics(val) {
      let form = {
        logisticCode: val.logisticCode,
        shipperCode: val.shipperCode,
        customerName: val.customerName,
        orderCode: val.orderCode,
      }
      this.$api
        .queryEbusiness(form)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
          } else {
            this.LogisticCode = res.data.LogisticCode
            this.ShipperName = res.data.ShipperName
            this.logisticsList = res.data.Traces
            this.showWL = true
          }

        })
        .catch((err) => {
          this.$Toast(err.msg);
        });
    },
    toPay() {

      if (this.cakaData.orderDetail) {
        localStorage.setItem('film_id', cakaData.productId)
        let seatList = []
        cakaData.productSubOrderList.forEach(element => {
          let seat = {
            seatId: element.seatId,
            price: element.showPrice
          }
          seatList.push(seat)

        });
        localStorage.setItem("seatList", JSON.stringify(seatList))
        localStorage.setItem("sale_price", this.cakaData.originalPrice)
        this.$router.push({
          path: "/Cashier",
          query: {
            type: this.orderType,
            order: this.orderNo,
            cardId: this.cakaData.cardId,
            creatTime: this.cakaData.createAt

          },
        });
      } else {
        let costPrice = 0
        this.cakaData.productSubOrderList.forEach(element => {
          costPrice += element.costPrice
        });
        this.cakaData.costPrice = costPrice
        localStorage.setItem('gooddetail', JSON.stringify(this.cakaData))
        if (this.cakaData.upgradeFlag === '0' && this.cakaData.bindEntrance === 'E3') {
          this.$router.push({
            path: "/MovieForGoodsCashier",
            query: {
              order: this.orderNo,
              cardId: this.cakaData.cardId,
              creatTime: this.cakaData.createAt
            },
          });
        } else
          this.$router.push({
            path: "/Cashier",
            query: {
              type: this.orderType,
              order: this.orderNo,
              cardId: this.cakaData.cardId,
              creatTime: this.cakaData.createAt
            },
          });
      }
    },
    showDialog() {
      this.$Dialog.confirm({
        title: "提示",
        message: "是否确认取消订单？",
        confirmButtonText: '我在想想',
        cancelButtonText: '取消订单'
      })
        .then(() => {
        })
        .catch(() => {
          // on cancel
          this.cancelPayment()
        });
    },
    goHome() {
      this.$api
        .jumpHomePage(localStorage.getItem("cardId"))
        .then((res) => {
          if (res.code === 1) {
             this.$Toast(res.msg);
          } else {
            if (res.data.balance === 0) {
              this.$router.replace({
                path: "/",
              });
            } else {
              let ticketType = localStorage.getItem("entrCode");
              if (ticketType === 'E4') {
                this.$router.replace({
                  path: "/HomeNew",
                });
              } else if (ticketType === 'E1') {
                this.$router.replace({
                  path: "/CakaHome",
                });
              } else {
                this.$router.replace({
                  path: "/MoviesHome",
                });
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });

    },
    cancelPayment() {
      this.$api
        .productcancelPayment(this.orderNo)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
          } else {
            this.$Toast(res.msg);
            this.$router.back();
          }
        })
        .catch((err) => {
          this.$Toast(err.msg);
        });
    },
    getPrice(num, price) {
      return parseFloat(num) * parseFloat(price).toFixed(2)
    },
    directWeChat(params) {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
        "appId": params.appId,     //公众号ID，由商户传入     
        "timeStamp": params.timeStamp,        //时间戳，自1970年以来的秒数     
        "nonceStr": params.nonceStr,//随机串     
        "package": params.package,
        "signType": params.signType,       //微信签名方式：     
        "paySign": params.paySign, //微信签名 
      },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.$Toast('支付成功');
            this.$router.back();
          }
        });
    },
    init(params) {
      console.log('params', params)
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.directWeChat(params), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.directWeChat(params))
          document.attachEvent('onWeixinJSBridgeReady', this.directWeChat(params))
        }
      } else {
        this.directWeChat(params)
      }
    },
    changePay() {
      // if (this.weiPrice) {
      //   this.weiPay()
      // } else {

      this.toPay()
      // }
    },
    getTotlePrice() {
      let totlePrce = 0
      this.cakaData?.productSubOrderList.forEach(element => {
        totlePrce += this.getPrice(element.emptionNum, element.showPrice)
      });
      return totlePrce
    },
    weiPay() {
      let form = {
        amount: this.weiPrice,
        userId: this.userId,
        channelOrderNo: this.orderNo
      }
      this.$api
        .generatePayOrderInfo(form)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
          } else {
            this.init(res.data)
          }

        })
        .catch((err) => {
          this.$Toast(err.msg);
        });
    },
    getOrderzyDetail() {
      this.$api
        .queryOrderInfoDetail(this.orderNo)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
          } else {
            if (res.data) {
              this.cakaData = res.data
              this.payList = this.cakaData.spendLogList
              this.weiPrice = this.cakaData.extraPay
              this.Paystatus = this.cakaData.status
            }
          }
        })
        .catch((err) => {
          // this.$Toast(err);
        });
    },
    changeItem(index) {
      this.selectIndex = index
    },
    getPhone(val) {
      if (val) {
        return this.$utils.formPhone(val);
      }
    },
    getStatus(status) {
      if (status == '0') {
        return '待支付'
      } else if (status == '1') {
        return '待发货'
      } else if (status == '2') {
        return '已发货'
      } else if (status == '3') {
        return '已完成'
      } else if (status == '4') {
        return '已取消'
      } else if (status == '5') {
        return '已超时'
      } else if (status == '10') {
        return '待收货'
      } else if (status == '11') {
        return '退货退款'
      } else if (status == '12') {
        return '更换商品'
      } else if (status == '13') {
        return '退款'
      } else if (status == '14') {
        return '换货'
      } else if (status == '15') {
        return '已关闭'
      } else if (status == '16') {
        return '待出餐'
      } else if (status == '17') {
        return '已出餐'
      } else if (status == '18') {
        return '出餐失败'
      } else if (status == '20') {
        return '部分出餐'
      }
    }
  },
  mounted() {
    this.orderNo = this.$route.query.orderNo
    this.orderType = this.$route.query.orderType
    if (this.orderType === 'hw') {
      this.showTag = true
    }
    this.userId = JSON.parse(localStorage.getItem("userInfo")).id
    this.phone = localStorage.getItem("loginphone")
    this.getOrderzyDetail()
  },
  watch: {},
};
</script>

<style lang='scss' scoped>
.box {
  background-color: #f5f5f5;
  height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(180deg, #D23B2E, rgba(210, 59, 46, 0.00));
  background-size: 100% 18rem;
  background-repeat: no-repeat;

  .img {
    width: 6rem;
    height: 6rem;
    border-radius: 0.5rem;
  }

  .goHomeTag {
    position: absolute;
    bottom: 3rem;
    right: 0;
    background-color: #D23B2E;
    color: white;
    padding: 0.3rem 1rem;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }

  .price {
    min-width: 3.5em;
    display: flex;
    margin-left: 1rem;
    flex-direction: row;
    justify-content: end;
    font-weight: 600;
    margin-top: 0.5rem;
    color: #D23B2E;
  }
  .priceB {
    min-width: 3.5em;
    display: flex;
    margin-left: 1rem;
    flex-direction: row;
    justify-content: end;
    color: #D23B2E;
  }
  .textTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-weight: 600;
    font-size: 1rem;
  }

  .textcontext {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 0.85rem;
    color: #666;
    margin-top: 0.2rem;
  }


  .bgc {
    background-color: #fff;
    border-radius: 0.5rem;
    margin: 0.8rem 1.1rem;
    padding: 1rem 1.1rem 0 1.1rem;
  }

  .bgcselect {
    background-color: #fff;
    border-radius: 0.5rem;
    margin: 0.5rem 0.8rem;
    border: 1px solid #D23B2E;
  }

  .msg {
    margin-top: 20px;
    padding-top: 5px;
    border-top: 1px solid #ededed;
  }
}

.sendjcsb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1rem;

}

.statusW {
  padding: 1rem 0 0.2rem 1.3rem;
  color: white
}

.statusB {
  padding: 1rem 0 0.2rem 1.3rem;
  color: #D23B2E
}

.cai {
  width: 100%;
}

.cardnp {
  display: flex;
  flex-direction: column;
  background-color: #F1F1F1;
  border-radius: 0.2rem;
  padding: 0.7rem 0.7rem;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.more {
  height: 2.5rem;
  border-radius: 0.5rem;
  line-height: 2.5rem;
  width: 90%;
  margin: 0.5rem;
  margin-left: 5%;
  font-size: 1rem;
  background-image: linear-gradient(#D23B2E, #fd6e7e);
  color: #f5f5f5;
  text-align: center;
}

.bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-top: 1px solid #ededed;
}

.itembottom {
  width: 100%;
  display: flex;
  margin-top: 0.5rem;
  justify-content: space-between;
  border-top: 1px solid #ededed;
}
</style>