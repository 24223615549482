<template>
  <div>
    <van-popup v-model="show" @closed="closed" position="bottom" round
      :style="{ minHeight: '40%', paddingTop: '1rem' }">
      <div style="background-color: white;padding-bottom: 1.5rem;">
        <div style="font-size: 1.2rem;color: #111111; margin: 0.5rem;">物流跟踪</div>
        <van-tabs v-if="dataList.length > 1" @click="scrollTo" v-model="navIndex"
          style="margin: 0.57rem 1rem 0.5rem 1rem;">
          <van-tab v-for="(item, index) in dataList" :title="getTitle(index)" :key="index">
          </van-tab>
        </van-tabs>
        <div style="font-size: 0.82rem;color: #111111; margin: 0.5rem;padding-top: 0.2rem;">
          <span>{{ shipperName }}</span>
          <span style="margin-left: 0.5rem;"> 物流单号：{{ logisticCode }}</span>
          <span @click="copyText(logisticCode)" style="color: #666;"> 复制</span>
        </div>
        <div style="max-height: 35rem;">
          <van-steps direction="vertical" :active="active">
            <van-step v-for="(item, index) in getlogisticsList()" :key="index">
              <span style="font-size: 1rem;">{{ item.acceptStation }}</span>
              <div style="font-size: 0.8rem;">{{ item.acceptTime }}</div>
            </van-step>
          </van-steps>
        </div>
        <div v-if="logisticsList.length > 2 && !showAll"
          style="display: flex; align-items: center; justify-content: center;">
          <div class="bottomButton" @click="isShowAll">
            查看全部
          </div>
        </div>
   
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    active: {
      type: Number
    },
    logisticsList: {
      type: Array,
    },
    logisticCode: {
      type: String
    },
    shipperName: {
      type: String
    },
    dataList: {
      type: Array,
    }
  },
  data() {
    return {
      show: true,
      showAll: false,
      navIndex: 0
    };
  },
  components: {},
  methods: {
    copyText(row) {
      this.$copyText(row).then(
        (e) => {
          this.$Toast('复制成功!')
          console.log(e);
        },
        (err) => {
          this.$Toast('复制失败,请重试!')
          console.log(err);
        }
      );
    },
    scrollTo(index) {
      this.$emit('SelectTab', this.dataList[index])
    },
    getTitle(val) {
      return '包裹' + (val + 1)
    },
    closed() {
      this.$emit("closed", false);
    },
    getlogisticsList() {
      if (this.showAll) {
        return this.logisticsList
      } else {
        return this.logisticsList.slice(0, 3)
      }
    },
    isShowAll() {
      this.showAll = !this.showAll
    },
  },
  mounted() {
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.addressItem {
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  background-color: white;
  padding: 0.5rem 0.8rem 0 0.8rem;
  border-bottom: 1px solid #fd6e7e;
}


.default {
  border-radius: 0.2rem;
  font-size: 0.8rem;
  background-color: red;
  color: white;
  padding-left: 0.2rem;
  padding-right: 0.2rem;

}

::v-deep .van-tab {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  color: #999;
  font-size: 0.85rem;
  line-height: 1.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
  max-width: 3.8rem;
  min-width: 3.8rem;
}



::v-deep .van-tabs--line .van-tabs__wrap {
  height: 2rem;

  .van-tabs__nav {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    background-color: #00000000;
    -webkit-user-select: none;
    user-select: none;
  }
}

::v-deep .van-tab--active {
  color: white;
  font-weight: 500;
  background: #D23B2E;
  border-radius: 0.9rem;
  height: 1.5rem;
  margin-top: 0.1rem;
  max-width: 3.8rem;
}

::v-deep .van-tabs__line {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 10.667vw;
  height: 0;

}
::v-deep .van-steps--vertical {
    padding: 0 0 1rem 8.533vw;
}
</style>